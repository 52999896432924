<template>
  <div>
    <div class="container reset-password">
      <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3"
      >
        <h3>{{ $t("sign.reset_pass") }}</h3>
      </div>
      <el-form :model="formData" :rules="rules" ref="resetForm">
        <el-form-item :label="$t('sign.original_password') + '：'" prop="oldpassword">
          <el-input type="password" v-model="formData.oldpassword"></el-input>
        </el-form-item>
        <el-form-item :label="$t('sign.new_pass') + '：'" prop="password">
          <el-input type="password" v-model="formData.password"></el-input>
        </el-form-item>
        <el-form-item :label="$t('sign.re_enter_pass') + '：'" prop="password_confirmation">
          <el-input type="password" v-model="formData.password_confirmation"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          @click.stop="submitForm('resetForm')"
        >{{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "../../api/api";
import { MessageBox, Loading } from "element-ui";

export default {
  data() {
    return {
      formData: {
        oldpassword: "",
        password: "",
        password_confirmation: "",
      }, 
    };
  },
  computed: {
    rules() {
      return {
        oldpassword: [
          { required: true, message: " ", trigger: 'change' }
        ],
        password: [
          { required: true, message: " ", trigger: 'change' }
        ],
        password_confirmation: [
          { required: true, message: " ", trigger: 'change' }
        ],
      }
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handeleChangePassword();
        } else {
          return false;
        }
      });
    },
    async handeleChangePassword() {
      let submitData = {
        userid: this.user.id,
        type: "resetpassword",
        ...this.formData
      };
      let _self = this;
      try {
        let res = await login(submitData);
        if (res.status === 200) {
          this.$helper.successNotify(res.message, function() {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            _self.$router.push("/");
          }, 1000);
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        console.log(error.message);
        this.$helper.errNotify(error.message);
      }
    }
  }
};
</script>
